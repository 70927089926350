import GLightbox from 'glightbox';

function gallery(imageToLightbox) {

  const imageList = [...document.querySelectorAll(imageToLightbox)]
  const imageData = imageList.map(el => {
    const href = el.href ? el.href : el.dataset.imageUrl
    const width = el.dataset.imageWidth
    const height = el.dataset.imageHeight
    return { href, type: 'image', width, height }
  })

  const gallery = GLightbox({
    elements: imageData,
    touchNavigation: true,
    loop: true
  })

  imageList.forEach(el => {
    el.addEventListener('click', (event) => openGallery(event, el))
  })

  function openGallery(event, el) {
    event.preventDefault()
    const openIndex = imageList.indexOf(el)
    gallery.openAt(openIndex);
  }

  return {
    gallery: true,
  }

}

window.gallery = gallery
