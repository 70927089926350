function catalogueHorizontalList(listItem = '.js-catalogue-product-list-item') {
  const initialState = {
    horizontalScroll: 0,
    maxScroll: getMaxScroll(listItem)
  }

  function handleRightClick() {
    ++this.horizontalScroll
  }

  function handleLeftClick() {
    --this.horizontalScroll
  }

  function showLeftArrow() {
    return this.horizontalScroll > 0
  }

  function showRightArrow() {
    return this.horizontalScroll < this.maxScroll
  }

  function handleProductListStyle() {
    const moveAmount = this.horizontalScroll * this.$refs.productListItem.clientWidth
    return `transform: translateX(-${ this.horizontalScroll > 0 ? moveAmount : 0 }px)`
  }

  return {
    ...initialState,
    leftArrow: {
      ['@click']: handleLeftClick,
      ['x-show.transition.opacity.duration.100ms']: showLeftArrow
    },
    rightArrow: {
      ['@click']: handleRightClick,
      ['x-show.transition.opacity.duration.100ms']: showRightArrow
    },
    productList: {
      ['x-bind:style']: handleProductListStyle
    }
  }
}

function getMaxScroll(selector) {
  const productListItem = document.querySelectorAll(selector)
  return [...productListItem].reduce((sum, el) => {
    if (el.getBoundingClientRect().right > window.outerWidth) return sum + 1
    return sum
  }, 0)
}

window.catalogueHorizontalList = catalogueHorizontalList
